// custom typefaces
import "typeface-montserrat"
import "typeface-merriweather"
// normalize CSS across browsers
import "./src/stylesheets/normalize.css"
// custom CSS styles
import "./src/stylesheets/style.scss"
import "./src/stylesheets/custom.scss"

// Highlighting for code blocks
import "prismjs/themes/prism.css"
